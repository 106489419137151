<mat-form-field [ngClass]="property.isValid ? 'valid' : 'invalid'">
    <mat-label appRemoveAriaOwns>{{property.displayKey ? property.Key : ''}}</mat-label>
    <span matPrefix *ngIf="property.hasIcon"><i
            class="{{property.iconClass}}"></i></span>
    <input nbInput matInput
           type="text"
           [matTimepicker]="timepicker"
           [(ngModel)]="property.Value"
           [readonly]="true"
    />
    <mat-timepicker #timepicker
                   interval="30min"
    ></mat-timepicker>
</mat-form-field>
