<mat-form-field [color]="property.isWarn ? 'warn': 'primary'"
                attr.data-cy="filter-input-multiline-{{property.Key}}"
                [ngClass]="property.isValid ? 'valid' : 'invalid'"
                [appearance]="appearance ?? 'fill'">
    <mat-label *ngIf="property.displayKey">{{ property.displayKey ? property.Key : '' }}</mat-label>
    <span matPrefix *ngIf="property.prefix">{{ property.prefix }}</span>
    <span matPrefix *ngIf="property.hasIcon"><i class="{{property.iconClass}}"></i></span>
    <textarea matInput [disabled]="disabled ? disabled : null"
              rows="{{property.rows}}"
              placeholder="{{property.placeholder}}"
              [(ngModel)]="property.Value"
              (keydown)="onKeyDown($event)"
              id="{{property.Key}}"></textarea>
    <span matSuffix *ngIf="property.suffix">{{ property.suffix }}</span>
    <mat-hint *ngIf="!property.validationError">{{ property.hint }}</mat-hint>
</mat-form-field>
