/*eslint-disable @typescript-eslint/member-ordering */

import {
    DeferrableFilter,
    FilterTemplate,
    SelectFilter,
    StringFilter,
    TimeFilter,
} from '@models/filter-types';
import {
    DaypartLookupItem,
    LookupItem,
    SegmentItem, StringLookupItem,
} from '../lookup';
import { take } from 'rxjs/operators';
import { Segmentation, UiConfig } from '@models/config';
import {
    cascadeToSingleFilter,
    generateSingleSelectFiltersWithSideText,
} from '@shared/helpers/functions/filter-helpers';
import { UrlStore } from '@shared/helpers/constants/url-store';
import { LookupV2Service } from '@services/lookup-v2/lookup-v2.service';
import {
    daypartsSingleFilter,
    startDateFilter,
    rateFilter,
} from '@models/base_filters';
import { FilterSettings } from '@models/filter';
import moment from 'moment';
import { EditableSegment } from '@models/rate-card/rate-card';

export class SegmentOneTimeFilters implements FilterTemplate {
    public loading: boolean;
    public primaryRateValue?: SelectFilter<StringLookupItem>;
    public tierValue?: number[] = [];
    public changeDates?: boolean = true;
    public rateCardTabsData?: number = 1;
    public savedReportLoaded = false;
    public showMetrics: boolean;

    public dynamicFilters: { [segmentType: string]: SelectFilter<SegmentItem> | StringFilter | TimeFilter } = {};

    constructor(
        private lookupSvc: LookupV2Service,
        public config: UiConfig,
        private tabNumber: number,
        private channelGroupId: number,
        private channelId: number,
        private spotType: number,
        private daypartId: number,
        private priceFloor: number,
        private priceMax: number,
        private primarySpotLength: number,
        private primaryTier: string,
        private segmentData?: EditableSegment,
        private hideRate?: boolean,
    ) {
        this.initDynamicSegmentationFilters();
    }

    settings: FilterSettings = {
        config: this.config,
        lookupSvc: this.lookupSvc,
        hasDynamicRates: true,
        tabNumber: this.tabNumber,
        screenName: UrlStore.screenNames.rateCard,
    };

    dayparts = daypartsSingleFilter(this.settings)
        .Defer(() =>
            this.lookupSvc.getDayparts([this.channelId],
                                       [this.spotType],
                                       this.settings))
        .OnLoad(((items: DaypartLookupItem[]) => {
            if (this.segmentData?.daypartId) {
                this.dayparts.Value = items.find(item => item.id === this.segmentData.daypartId);
            } else {
                this.dayparts.Value = items.find(item => item.id === this.daypartId);
            }
        }))
        .OnChange((item: LookupItem) => {
            if (this.anyFilterOpen() || !item) {
                return;
            }
            let segmentation = this.config
                .groupSegmentation[this.channelGroupId]?.rate_card;

            segmentation = segmentation.filter(x => !x.is_dow);

            if (segmentation?.length) {
                const firstSegmentation = segmentation.sort((first, second) => first.segment_order - second.segment_order)[0];

                const firstFilter = this.dynamicFilters[firstSegmentation.segment_type] as SelectFilter<SegmentItem>;
                if(!firstFilter){
                    return;
                }
                firstFilter.isLoading = true;
                const channelIdsSelected = [this.channelId];
                const daypartIdsSelected = item.id;
                this.lookupSvc.getRateCardAddRowPopupSegments(channelIdsSelected, [daypartIdsSelected], firstSegmentation)
                    .pipe(take(1))
                    .subscribe(result => {
                        cascadeToSingleFilter(
                            firstFilter,
                            result,
                            this.segmentData?.dynamicSegmentation[firstSegmentation.segment_type],
                            false,
                        );
                    }, err => {
                        console.error(err);
                        firstFilter.isLoading = false;
                        firstFilter.loadError = 'Failed to Load';
                    });
            }
        })
        .IsDynamicFilterParent(true)
        .Required(true);

    oneTimeDate = startDateFilter().SetKey('Date')
        .Default(this.segmentData?.oneTimeSegment.date ? moment(this.segmentData?.oneTimeSegment.date) : undefined)
        .Required(true)
        .Filter((v: moment.Moment) => v >= moment().startOf('day'));

    rateFilter = rateFilter(this.priceFloor, this.priceMax, this.primarySpotLength,  this.primaryTier)
        .Default(this.segmentData?.rate).Prefix('$').Toggle(this.hideRate);

    initDynamicSegmentationFilters(): void {
        const groupSegmentation = this.config.groupSegmentation[this.channelGroupId]?.rate_card || [];
        this.dynamicFilters = generateSingleSelectFiltersWithSideText(
            () => [this.channelId],
            () => [this.dayparts.Value.id],
            groupSegmentation.filter(x => !x.is_dow),
            (
                channelIds: number[],
                daypartIds: number[],
                segmentation: Segmentation,
                filterValues: {
                    [segmentType: string]: number[];
                },
                clearCache?: boolean,
            ) => this.lookupSvc.getRateCardAddRowPopupSegments(channelIds, daypartIds, segmentation, filterValues, clearCache),
            this.segmentData?.dynamicSegmentation,
        );
    }

    anyFilterOpen: () => boolean = () =>
        Object.keys(this)
            .filter(key => this[key] instanceof DeferrableFilter)
            .filter(key => (this[key] as DeferrableFilter<unknown, unknown>).isOpened)
            .length > 0;
}
