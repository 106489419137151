import {
    GridApi,
    GridReadyEvent,
    RowNode,
    Column,
    RefreshCellsParams,
    ColumnGroupOpenedEvent,
    ICellRendererParams, ProvidedColumnGroup,
} from 'ag-grid-community';
import { Output, EventEmitter, Directive } from '@angular/core';
import { GridApis } from '@models/lookup';
import { CheckBoxCellParams } from '@models/ag-grid';
import { ColDef } from 'ag-grid-community';
import { cellClass, cellClassRules } from '@shared/helpers/ag-grid/ag-grid-builder';

export const REMOVE_ROW_COLDEF: ColDef = {
    headerName: ' ',
    field: 'removeRow',
    cellClassRules: cellClassRules(),
    cellClass: (params) => cellClass(params, undefined),
    editable: false,
    sortable: false,
    suppressMenu: true,
};

cellClassRules();

export const REMOVE_EDIT_COLDEF: ColDef = {
    headerName: ' ',
    field: 'removeEditRow',
    cellClassRules: cellClassRules(),
    cellClass: (params) => cellClass(params, undefined),
    editable: false,
    sortable: false,
};

@Directive()
export abstract class AgGridApiDirective<T> {
    @Output() gridApiReady: EventEmitter<GridApis> = new EventEmitter();

    public gridApi: GridApi<T>;


    onGridReady(params: GridReadyEvent): void {
        this.gridApi = params.api;
        this.gridApiReady.emit({ gridApi: this.gridApi });
    }

    onGridReadyAutoSize(params: GridReadyEvent): void {
        this.onGridReady(params);
        if (this.gridApi) {
            this.gridApi.autoSizeAllColumns();
        }
    }

    onGridReadySizeToFit(params: GridReadyEvent): void {
        this.onGridReadyAutoSize(params);
        this.gridApi?.sizeColumnsToFit();
    }

    resizeColumnGroupOnStateChange(event: ColumnGroupOpenedEvent): void {
        if (event.type === 'columnGroupOpened') {
            event.columnGroup.getChildren().forEach((child: ProvidedColumnGroup) => {
                if (child.getColumnGroupShow() !== 'closed') {
                    this.gridApi.autoSizeColumn(child.getId(), false);

                }
            });
        } else if (event.type === 'columnGroupClosed') {
            event.columnGroup.getChildren().forEach((child: ProvidedColumnGroup) => {
                if (child.getColumnGroupShow() !== 'open') {
                    this.gridApi.autoSizeColumn(child.getId(), false);

                }
            });
        }
        this.gridApi.refreshCells({ force: true });
    }

    setColumnVisible(colId: string, display: boolean): void {
        this.gridApi?.setColumnVisible(colId, display);
        const column = this.gridApi?.getColumn(colId);
        if (column) {
            column.getColDef().hide = !display;
        }
    }

    refreshCellsAndAutosizeColumns(rowNodes?: RowNode[], columns?: Column[], force?: boolean): void {
        const params: RefreshCellsParams = {};
        if (rowNodes) {
            params.rowNodes = rowNodes;
        }
        if (columns) {
            params.columns = columns;
        }
        if (force) {
            params.force = force;
        }
        this.gridApi.refreshCells(params);
        this.gridApi.autoSizeAllColumns();
    }

    refreshCellsAndSizeColumnsToFit(rowNodes?: RowNode[], columns?: Column[], force?: boolean): void {
        const params: RefreshCellsParams = {};
        if (rowNodes) {
            params.rowNodes = rowNodes;
        }
        if (columns) {
            params.columns = columns;
        }
        if (force) {
            params.force = force;
        }
        this.gridApi.refreshCells(params);
        this.gridApi?.sizeColumnsToFit();
    }

    toggleSelectAll(isChecked: boolean, rowData: T[], field: string, filter: (row: T) => boolean = () => true): void {
        rowData.filter(filter).forEach(row => {
            row[field] = isChecked;
        });
        this.gridApi.setGridOption('rowData', rowData); // Necessary to override a partial selection with select all
        this.gridApi.refreshCells({ columns: [field]});
    }

    selectRowForSelectAll(params: CheckBoxCellParams, rowData: T[], filter: (row: T) => boolean = () => true) {
        const field = params.colDef.field;
        const allSelected = this.getSelectedRows(rowData, field);
        if (rowData.filter(filter).length === allSelected.length) {
            this.gridApi.getColumnDef(field).headerComponentParams.checked = true;
            this.gridApi.getColumnDef(field).headerComponentParams.indeterminate = false;
            this.gridApi.refreshHeader();
        } else if (allSelected.length > 0) {
            this.gridApi.getColumnDef(field).headerComponentParams.checked = false;
            this.gridApi.getColumnDef(field).headerComponentParams.indeterminate = true;
            this.gridApi.refreshHeader();
        } else {
            this.gridApi.getColumnDef(field).headerComponentParams.checked = false;
            this.gridApi.getColumnDef(field).headerComponentParams.indeterminate = false;
            this.gridApi.refreshHeader();
        }
    }

    removeEditCellRenderer(params: ICellRendererParams, removeFunc?: (params) => void, editFunc?: (params) => void): HTMLElement {
        const rootElement = document.createElement<'span'>('span');
        rootElement.classList.add('remove-cell-class');
        rootElement.innerHTML = '';
        if (editFunc) {
            rootElement.innerHTML +=
                '<a class="button is-small is-primary margin-right-half-em margin-left-half-em is-edit ">' +
                '<i class="fas fa-fw fa-edit"></i>' +
                '</a>';
        }
        if (removeFunc) {
            rootElement.innerHTML +=
                '<a class="button is-small is-primary margin-right-half-em margin-left-half-em is-remove">' +
                '<i class="fas fa-fw fa-trash"></i>' +
                '</a>';
        }
        if (editFunc) {
            const editAction = rootElement.querySelector('.is-edit');
            editAction.addEventListener('click', editFunc);
        }
        if (removeFunc) {
            const removeAction = rootElement.querySelector('.is-remove');
            removeAction.addEventListener('click', removeFunc);
        }
        return rootElement;
    }

    protected getSelectedRows(rowData: T[], field: string): T[] {
        return rowData.filter(row => row[field]);
    }

}

