import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { fromEvent, interval, of, Subscription } from 'rxjs';
import { LookupItem, PoliticalInitializationTiers, StringLookupItem } from '@models/lookup';
import {
    getAllDisplayText,
    getDisplayText,
    isDeferrableFilter,
    isMultiOptionSelected,
    isMultiSelectFilter,
    isSelectFilter,
    removeCommasFromValue,
    segmentTrack,
} from '@shared/helpers/functions/helpers';
import { debounce } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatInput } from '@angular/material/input';
import { MAT_SELECT_CONFIG, MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import {
    BooleanFilter,
    DateFilter,
    DateRangeFilter,
    Filter,
    FilterTypes, MultilineTextFilter,
    MultiSelectFilter,
    NumberFilter,
    SelectFilter,
    StringFilter,
    TimeFilter,
} from '@models/filter-types';

const SPACE_KEY_CODE = 'Space';
const TAB_KEY_CODE = 'Tab';
const KEY_A_CODE = 'KeyA';
const SEARCH_DELAY = 500;

type SupportedTypes = StringLookupItem | LookupItem;

@Component({
    selector: 'app-filter-input',
    templateUrl: './filter-input.component.html',
    styleUrls: ['./filter-input.component.scss'],
    providers: [
        { useValue: { overlayPanelClass: 'select-overlay' }, provide: MAT_SELECT_CONFIG },
    ],
    standalone: false,
})
export class FilterInputComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

    @Input() overrideDisabled: boolean;
    @Input() preventTab = false;
    @Input() appearance?: 'outline' | 'fill';
    @Input() property:
        SelectFilter<SupportedTypes>
        | MultiSelectFilter<SupportedTypes>
        | NumberFilter
        | StringFilter
        | DateFilter
        | DateRangeFilter
        | BooleanFilter
        | TimeFilter
        | MultilineTextFilter
        | Filter<SupportedTypes>;
    @Input() disabledOptions: SupportedTypes[];

    @Output() filterRef: EventEmitter<unknown> = new EventEmitter<unknown>();
    @Output() optionDeleteButtonClicked: EventEmitter<unknown> = new EventEmitter<unknown>();
    @Output() valueChanged: EventEmitter<unknown> = new EventEmitter<unknown>();
    @Output() keyDownEvent: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

    @ViewChild('search') searchTextBox: ElementRef;
    @ViewChild('inputFilter') inputFilterHtml: MatInput;
    @ViewChild('selectFilter') selectFilterHTML: MatSelect;

    disabled = false;

    inputType: string = undefined;

    searchTextboxControl = new UntypedFormControl();
    selectedValues: SupportedTypes[] = [];

    isSearching = false;

    protected readonly parseFloat = parseFloat;

    protected readonly filterTypes = FilterTypes;

    private subs: Subscription[] = [];

    constructor(private router: Router) {
    }

    ngOnInit() {
        this.inputType = this.getInputType();
    }

    ngOnChanges() {
        this.disabled = this.property?.isReadonly || this.overrideDisabled;
    }

    ngAfterViewInit(): void {
        if (this.inputFilterHtml) {
            this.filterRef.emit(this.inputFilterHtml);
            this.property.filterRef = this.inputFilterHtml;
        } else if (this.selectFilterHTML) {
            this.filterRef.emit(this.selectFilterHTML);
            this.property.filterRef = this.selectFilterHTML;
        }
    }

    getInputType(): string {
        switch (this.property?.Type) {
        case FilterTypes.StringFilterType:
            return 'text';
        case FilterTypes.NumberFilterType:
            return 'number';
        case FilterTypes.DateFilterType:
            return 'date';
        }
    }

    /**
     * Used to filter data based on search input
     */
    filter(name: string = this.searchTextboxControl.value): void {
        this.setSelectedValues();
        if (isDeferrableFilter(this.property)) {
            if (name === '' && this.property.isServerFilter) {
                this.property.filteredOptions = of(this.selectedValues.map(item => item));
            } else {
                this.property.Filter(name.toLowerCase(), this.property.options);
            }
            this.property.filteredOptions.subscribe(() => this.isSearching = false);
        }
    }

    /**
     * Remove from selected values based on uncheck
     */
    selectionChange(event) {
        if (event.isUserInput && event.source.selected === false) {
            this.selectedValues.forEach((value, index) => {
                if (value.name === event.source.value.name && value.id === event.source.value.id) {
                    this.selectedValues.splice(index, 1);
                }
            });
        }
    }

    openedChange() {
        if (isDeferrableFilter(this.property)) {
            this.property.isOpened = true;
            if (this.property.isFilterable) {
                if (isMultiSelectFilter(this.property)) {
                    this.selectedValues = [...this.property.Value];
                } else if (isSelectFilter(this.property)) {
                    this.selectedValues = [this.property.Value];
                }
                this.searchTextboxControl.setValue('');
                this.filter();
                const searchInput = fromEvent(this.searchTextBox.nativeElement, 'input')
                    .pipe(debounce(() => {
                        this.isSearching = true;
                        return interval(SEARCH_DELAY);
                    }));
                this.subs.push(searchInput.subscribe(() => this.filter()));
                this.searchTextBox.nativeElement.focus();
            }
        }
    }


    closedChange() {
        if (isDeferrableFilter(this.property)) {
            this.property.isOpened = false;
            if (this.property.isFilterable) {
                this.filter('');
                this.subs.forEach(o => o.unsubscribe());
            }
            if (this.property.isServerFilter) {
                if (isMultiSelectFilter(this.property)) {
                    this.selectedValues.forEach(value => {
                        if (isMultiSelectFilter(this.property)) {
                            if (!isMultiOptionSelected(value, this.property.Value as LookupItem[])) {
                                this.property.Value.push(value);
                            }
                        }
                    });
                    this.property.filteredOptions = of(this.property.Value);
                } else {
                    this.property.filteredOptions = of([this.property.Value]);
                }

            }
            // This forces a cascade update
            segmentTrack(
                'User Selected From Dropdown',
                {
                    dropdown: this.property.Key,
                    selection: Array.isArray(this.property.Value)
                        ? getAllDisplayText(this.property.Value)
                        : getDisplayText(this.property.Value),
                    screen: this.router.url,
                },
            );
            this.property.refreshFilter();
            this.valueChanged.emit();
        }
    }

    clearSearch() {
        this.searchTextboxControl.setValue('');
        this.filter();
    }

    /**
     * Set selected values to retain the state
     */
    setSelectedValues() {
        if (isMultiSelectFilter(this.property)) {
            if (this.property.Value && this.property.Value.length > 0) {
                this.property.Value.forEach((e) => {
                    if (!isMultiOptionSelected<SupportedTypes>(e, this.selectedValues)) {
                        this.selectedValues.push(e);
                    }
                });
            }
            if (this.selectedValues && this.selectedValues.length > 0) {
                this.selectedValues.forEach(value => {
                    if (isMultiSelectFilter(this.property)) {
                        if (!isMultiOptionSelected(value, this.property.Value)) {
                            this.property.Value.push(value);
                        }
                    }
                });
            }
        }
    }

    async keyPress(event: KeyboardEvent) {
        if (event && (event.code === SPACE_KEY_CODE)) {
            event.stopPropagation();
        }
        if (this.preventTab && event?.code === TAB_KEY_CODE) {
            event.preventDefault();
        }
        if (isMultiSelectFilter(this.property)) {

            if (event.code === KEY_A_CODE && event.ctrlKey && this.property.Value && this.property.Value.length) {
                this.subs.push(this.property.filteredOptions.subscribe((options) => {
                    const valuesToRemove = [];
                    if (this.doesControlContainsAllOptions(options)) {
                        options.forEach((option) => {
                            if (isMultiOptionSelected(option, this.selectedValues)) {
                                valuesToRemove.push(option);
                            }
                        });
                        valuesToRemove.forEach(value => {
                            this.selectedValues.splice(
                                this.selectedValues.findIndex(
                                    selectedValue => selectedValue.name === value.name && selectedValue.id === value.id,
                                ), 1,
                            );
                        });
                    }
                }));
            }
        }
    }

    doesControlContainsAllOptions(options: SupportedTypes[]): boolean[] {
        return options.map(option => {
            if (isMultiSelectFilter(this.property)) {
                return this.property.Value.includes(option);
            }
        });
    }

    selectAll(event: MatCheckboxChange) {
        if (isMultiSelectFilter(this.property)) {
            this.property.SelectAll(event.checked);
            if (!event.checked) {
                this.selectedValues = [];
            }
        }
    }

    ngOnDestroy() {
        this.subs.forEach(o => o.unsubscribe());
    }

    removeCommasFromValue(value: string): string {
        return removeCommasFromValue(value);
    }

    deleteItem(option): void {
        this.optionDeleteButtonClicked.emit(option);
    }

    // The purpose of this function is to determine if an array is an array of political initialization tiers, so we need to use any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getPoliticalOptions(options: any[]): PoliticalInitializationTiers[] {
        return options.filter(item => item.isPolitical);
    }

    // The purpose of this function is to determine if an array is an array of political initialization tiers, so we need to use any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getDynamicOptions(options: any[]): PoliticalInitializationTiers[] {
        return options.filter(item => item.isDynamic);
    }

    // The purpose of this function is to determine if an array is an array of political initialization tiers, so we need to use any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getPCodeOptions(options: any[]): PoliticalInitializationTiers[] {
        return options.filter(item => item.isPCode);
    }

    // The purpose of this function is to determine if an array is an array of political initialization tiers, so we need to use any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    checkPCodeTierAvailability(options: any[]): options is PoliticalInitializationTiers[] {
        return !!options.find(item => item.isPCode);
    }
}
