import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import {
    MultilineTextFilter,

} from '@models/filter-types';

@Component({
    selector: 'app-multiline-text',
    standalone: false,
    templateUrl: './multiline-text.component.html',
    styleUrl: './multiline-text.component.scss',
})
export class MultilineTextComponent implements OnChanges {
    @Input() appearance?: 'outline' | 'fill';
    @Input() property: MultilineTextFilter;

    @Output() valueChanged: EventEmitter<unknown> = new EventEmitter<unknown>();
    @Output() keyDownEvent: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

    disabled = false;

    ngOnChanges() {
        this.disabled = this.property?.isReadonly;
    }

    onKeyDown(event: KeyboardEvent) {
        this.keyDownEvent.emit(event);
    }

}
