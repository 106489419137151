import { Pipe, PipeTransform } from '@angular/core';
import { Filter, FilterTypes, MultilineTextFilter } from '@models/filter-types';

@Pipe({
    name: 'isMultilineTextFilter',
    standalone: false,
})
export class IsMultilineTextFilterPipe implements PipeTransform {

    transform(value: Filter<unknown>): value is MultilineTextFilter {
        return value?.Type === FilterTypes.MultilineTextFilterType;
    }

}
