import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UiConfig } from '@models/config';
import { FilterTemplate } from '@models/filter-types';
import { SavedReport, SavedReportData, SavedReportSomething } from '@models/lookup';
import { AuthService } from '@services/auth/auth.service';
import { LookupV2Service } from '@services/lookup-v2/lookup-v2.service';
import { NotificationService } from '@services/notification/notification.service';
import { ConfirmDialogComponent, CONFIRM_DIALOG } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { MessageDialogComponent } from '@shared/components/message-dialog/message-dialog.component';
import { TextDialogComponent } from '@shared/components/text-dialog/text-dialog.component';
import { segmentTrack } from '@shared/helpers/functions/helpers';
import { EmailReportsPopUpComponent } from '@shared/components/report-components/email-reports-pop-up/email-reports-pop-up.component';
import moment from 'moment';


@Component({
    selector: 'app-load-report-popup',
    templateUrl: './load-report-popup.component.html',
    styleUrls: ['./load-report-popup.component.scss'],
    standalone: false,
})
export class LoadReportPopupComponent implements OnInit {


    public savedReports: SavedReport[] = [];
    public url: string;
    public filter: SavedReportSomething | FilterTemplate;
    report: SavedReport;
    isLoading = true;
    isDeleting = false;
    userConfig: UiConfig;

    constructor(
    public dialogRef: MatDialogRef<LoadReportPopupComponent>,
    public matDialog: MatDialog,
    private authSvc: AuthService,
    private lookupV2Service: LookupV2Service,
    private snackbarService: NotificationService,
    @Inject(MAT_DIALOG_DATA) private data: SavedReportData,
    ) {
        this.url = data.url;
        this.filter = data.filter;
        this.savedReports = data.reports;
        this.sortReportsByDisplayOrder();
    }

    ngOnInit(): void {
        this.userConfig = this.authSvc.getConfig();
    }

    sortReportsByDisplayOrder(): void {
        this.savedReports.sort((a, b) => a.displayOrder - b.displayOrder);
    }

    // This function is not being used until Edit Report Functionality is enabled
    /*
  --------------------END OF EDIT REPORT LOGIC--------------------

  openEditReportDialog(report: unknown) {

    let editReportChanges: EditSavedReportChanges = {
      name: {
        original: report.name,
        current: report.name
      },
      favorited: {
        original: report.isFavorited,
        current: report.isFavorited
      },
      saveMetricSelections: {
        original: !(report.savedMetrics === null),
        current: !(report.savedMetrics === null)
      },
      startDate: {
        original: moment(report.startDate),
        current: moment(report.startDate)
      },
      endDate: {
        original: moment(report.endDate),
        current: moment(report.endDate)
      }
    };
    const nameFilter = new StringFilter('Report Name', report.name)
    .Required(true)
    .OnChange(change => {editReportChanges.name.current = change});

    const periodSelectFilter = new SelectFilter<LookupItem>('Period')
    .Options(this.filter.period.options)
    const numberOfPeriodsFilter = new NumberFilter('Number of Periods')
    .Min(0)

    const startDateSelectFilter = new DateFilter('Start Date')
      .Default(moment(report.startDate))
      .View('Week')
      .OnChange(change => {editReportChanges.startDate.current = change});

    const endDateSelectFilter = new DateFilter('End Date')
      .Default(moment(report.endDate))
      .View('Week')
      .IsEndDate(true)
      .OnChange(change => {editReportChanges.endDate.current = change});

    const dateRangeOptionsFilter = new RadioFilter<FilterOption>('')
    .DisplayKey(false)
    .Options([
        {
            name: 'Save Fixed Dates',
            filterFunc: () => true
        },
        {
            name: 'Save Rolling Dates',
            filterFunc: () => true
        }
    ])

    dateRangeOptionsFilter.Value = dateRangeOptionsFilter.options[0];
    const saveMetricSelectionsFilter = new BooleanFilter('Save Metric Selections', report.savedMetrics !== null)
    .Slider(true)

    const setAsFavoriteFilter = new BooleanFilter('Favorited', report.isFavorited)
    .Slider(true)
    .OnChange(change => {editReportChanges.favorited.current = change});

    const dialog = this.matDialog.open(EditReportPopupComponent, {
      data: {
        reportName: report.reportName,
        reportId: report.reportId,
        filters: {
          nameFilter,
          saveMetricSelectionsFilter,
          startDateSelectFilter,
          endDateSelectFilter,
          dateRangeOptionsFilter,
          periodSelectFilter,
          numberOfPeriodsFilter,
          setAsFavoriteFilter
        }
      },
    });

    dialog.afterClosed().subscribe(
      result => {
        console.log(editReportChanges)

        const changes = {}
        // TODO - use changes dictionary as json body for edit request

      },
      error => {
        console.error(error);
        this.snackbarService.openSnackBar(`Could not delete report`, 'error');
      }
    );
  }

  --------------------END OF EDIT REPORT LOGIC--------------------
  */

    toggleIsFavorited(report: SavedReport) {
        const screenName = this.url;
        const isFavoritedValue = !report.isFavorited;
        this.lookupV2Service.toggleSaveReportIsFavorited(screenName, report.reportId, isFavoritedValue).subscribe(() => {
            const reportIndex = this.savedReports.findIndex(r => r.reportId === report.reportId);
            this.savedReports[reportIndex].isFavorited = isFavoritedValue;
        });
    }

    openEmailReportDialog(report: SavedReport) {
        this.matDialog.open(EmailReportsPopUpComponent, {
            width: '24em',
            data: {
                report,
            },
        });
    }

    isConfiguredEmailReport(report: SavedReport) {
        return report?.emailReports?.frequencyId && report?.emailReports?.isActive;
    }

    isEndDatePassed(endDate){
        if(!endDate){
            return false;
        }
        const givenDate=moment(endDate,'YYYY-MM-DD');
        const today=moment().startOf('day');
        return givenDate.isBefore(today);
    }

    copyReportLink(report: SavedReport) {
        const shareableURL= new URL(window.location.origin + window.location.pathname.split('/r/')[0].split('/t/')[0]);
        if (this.data.tabId){
            shareableURL.pathname += `/t/${this.data.tabId}`;
        }
        this.lookupV2Service.createSharedReport(report.reportId).subscribe(
            shareId => {
                shareableURL.pathname += `/r/${shareId}`;
                navigator.clipboard.writeText(shareableURL.toString()).then(()=>{
                    const copyDialogRef = this.matDialog.open(MessageDialogComponent, {
                        data: {
                            message: 'Copied Report URL',
                        },
                    });
                    copyDialogRef.afterOpened().subscribe(_ => {
                        setTimeout(() => {
                            copyDialogRef.close();
                        }, 2000);
                    });
                }).catch((err)=>console.error(err));
            },
        );
    }

    openDeleteReportDialog() {
        if (this.report.isGlobal && !this.userConfig.roleCapability.rate_optics.general.create_global_report) {
            this.matDialog.open(TextDialogComponent, {
                data: {
                    text: 'You do not have the permission to delete global reports',
                    trueButtonText: 'Ok',
                    falseButtonText: undefined,
                    noClickClose: true,
                },
            });
            return;
        }
        const dialog = this.matDialog.open(ConfirmDialogComponent, {
            data: {
                text: `Delete ${this.report.reportName}?`,
                autoConfirm: false,
                noClickClose: true,
                confirmText: 'Yes',
                denyText: 'No',
                denyClose: true,
            },
        });

        dialog.afterClosed().subscribe(result => {
            if (result === CONFIRM_DIALOG) {
                const screenName = this.url;
                this.lookupV2Service.deleteSavedReport(screenName, this.report.reportId)
                    .subscribe(() => {
                        const reportIndex = this.savedReports.findIndex(r => r.reportId === this.report.reportId);
                        this.savedReports.splice(reportIndex, 1);
                        this.snackbarService.openSnackBar('Deleted Report', 'success');
                    }, error => {
                        console.error(error);
                        this.snackbarService.openSnackBar('Could not delete report', 'error');
                    });
            }
        });

    }

    loadFilters(report: SavedReport){
        if (report.filter) {
            this.filter = report.filter;
            this.filter.savedMetrics = report.savedMetrics.savedMetrics;
            this.filter.savedMetricsOrder = report.savedMetrics.savedMetricsOrder;
            this.filter.savedSegments = report.savedSegments.savedSegments;
            this.filter.savedSegmentsOrder = report.savedSegments.savedSegmentsOrder;
        }
        this.report = report;
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.savedReports, event.previousIndex, event.currentIndex);
        this.saveReportAccess();
    }

    saveReportAccess(){
        const reportsAccessData = this.savedReports.map(report => ({
            reportId: report.reportId,
            userId: report.userId,
            isFavorited: report.isFavorited,
        }));
        const screenName = this.url;
        this.lookupV2Service.userSaveReportAccess(screenName, reportsAccessData).subscribe(() => {
        });
    }

    loadFiltersAndClose(){
        this.report.data = this.data;
        this.dialogRef.close({ filter: this.filter, selectedReport: this.report });
        segmentTrack('User Selected Report',
                     {
                         module: this.report?.data?.url,
                         reportId: this.report?.reportId,
                         reportName: this.report?.reportName,
                         isFavorited: this.report?.isFavorited,
                         isGlobal: this.report?.isGlobal,
                         useRollingDates: this.report?.filter?.useRollingDates,
                     });
    }
    closeDialog() {
        this.dialogRef.close();
    }

}
